import request from '@/utils/request'


// 获得视频分页
export function getPicsPage(query) {
    return request({
        url: '/ich/pics/page',
        method: 'get',
        params: query
    })
}
// 获得直播分页
export function getPicsDetail(id) {
    return request({
        url: '/ich/pics/get?id='+id,
        method: 'get',
        
    })
}