<template>
  <div class="container" v-if="content">
    <breadcrumb :current="{ title: content.title }"></breadcrumb>
    <div class="video-box">
      <img @click="$refs.carousel.prev()" style="transform: rotate(-90deg); width: 40px;height: 25px;margin-right: 30px;" src="@/assets/images/swiper-pagination.png" alt="">
        <div class="carousel">
          <el-carousel loop @change="swiper" :autoplay="false" indicator-position="none" ref="carousel" trigger="click" height="500px" style="width: 850px;" arrow="never">
          <el-carousel-item v-for="item in content.picArr" :key="item.id">
          <img :src="item.pic" alt="" style="width: 100%;height: 100%;object-fit: contain;">
          </el-carousel-item>
        </el-carousel>
        <p style="font-size: 24px;margin-top: 20px;color: #3A8DA6;">{{content.picArr[current].name||' '}}</p>
      </div>
      <img @click="$refs.carousel.next()" style="transform: rotate(90deg); width: 40px;height: 25px;margin-left: 30px;" src="@/assets/images/swiper-pagination.png" alt="">
    </div>

    <div class="img-pre">
      <div class="img-box" @click="showstep(index)" :class="{ active: current == index }"
        v-for="(item, index) in content.picArr" :key="index">
        <img :src="item.pic" style="object-fit: cover;" alt="">
      </div>
    </div>
     <!-- 选项卡 -->
     <category isDetail @change="changeCategory" :categorys="categorys"></category>
     <txt-view v-if="categoryIndex === 0" class="txt" :txt="content.txt"></txt-view>
     <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
     <recommend url="pics" :id="$route.params.id"  :contentType="$route.meta.contentType" />
  </div>
</template>

<script>
// import { videoPlayer } from 'vue-video-player'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

// //引入 hls,视频直播(m3u8)必须引入的
// import 'videojs-contrib-hls'
// //播放rtmp视频
// import 'videojs-flash'


import {getPicsDetail} from "@/api/pics/pics";

import MainInfo from "@/components/MainInfo";
import Recommend from '@/components/RelatedRecommend'


export default {
  name:'video-detail',
  components: {
    MainInfo,Recommend
  },
  watch: {
     $route(){
      this.getData()

     } 
    },
  data() {
    return {



      current: 0,
      content:null,
      categoryIndex: 0,//分类下标
      categorys:[
            {
                name: '作品详情',
                newsType: 1
            },{
                name: '评论',
                newsType: 2
            }
      ]
    }
  },
  created () {
      this.getData()
  },
  methods: {
    swiper(e) {
      this.current = e
    },
    showstep(e){
      this.$refs.carousel.setActiveItem(e)
    },
    changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
    getData() {
      getPicsDetail(this.$route.params.id).then(res=>{
        if(res.code === 0){
          res.data.picArr=JSON.parse(res.data.picsJson)
          this.content=res.data
          // this.playerOptions.sources[0].src = res.data.pullUrl;

          // console.log(res);
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
 
.img-pre {
      // width: 62px;
      // overflow-x: scroll;
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      // box-sizing: border-box;
      .img-box {
        width: 220px;
        height: 130px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: .1);
        // margin-bottom: 10px;

        img {
          border-radius: 10px;
          width: 200px;
          height: 120px;
         
          // border: 1px solid #ccc;
          // h
        }
      }
      .active {
        // background-color: ;
        // padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        overflow: hidden;
        // width: 230px;
        // height: 145px;
        background: url('../../assets/images/big-bg.png') no-repeat center;
        background-size: contain;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0) !important;
        // border: 2px solid red;
      }

     

      // height:;
    }
.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item {
     background-color: #fff;
  }
  
.container {
  width: 1200px;
    margin: 0 auto;
    .video-box {
      // height: 800px;
      background: url('https://oss.culturalcloud.net/furong/202311/02101728qrki.png') no-repeat center center scroll;
      background-size: 100% 100%;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 32px;
        line-height: 64px;
        color: #3A8DA6;
      }
      .info {
        font-size: 14px;
        margin-bottom: 20px;

      }
    }
}
</style>
